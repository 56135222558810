import React from 'react';
import { Helmet } from 'react-helmet';

import Bootstrap from '../../components/bootsrap';

import Ring from '../../components/ring-visual';

import './about.scss';

const About = () => {
  return (
    <Bootstrap>
      <Helmet>
        <title>About | Maneesh Chiba</title>
      </Helmet>
      <article className="grid-layout">
      <Ring />
        <main className="copy">
          <div className="center">
            <h1 className="title">
              A little more about&nbsp;me
          </h1>
            <p>
              I&apos;m a front-end developer. I have a background in visual design &amp; client side development, specializing in component-driven development and design systems. Originally from Johannesburg 🇿🇦, I now call Toronto 🇨🇦 home, connecting like-minded communities at <a target={'blank'} href={'https://vanillaforums.com/'}>Vanilla Forums.</a>
            </p>
            <p>
              Over the last decade, I’ve worked at start-ups (like <a target={'blank'} href={'https://www.mosaicmfg.com/products/canvas'}>Mosaic Manufacturing</a>), agencies (like&nbsp;<a target={'blank'} href={'https://www.vmlyr.com/'}>VMLY&amp;R</a>) and enterprise environments (like <a target={'blank'} href={'https://www.sophi.io/'}>The Globe and Mail</a>). Building skills that include front-end focused development, architecture and developer experience, as well as interface design and some light back-end development.
          </p>
            <p>
              In my spare time, I like making <a target={'blank'} href={'https://codepen.io/collection/XyzwqJ?sort_order=desc&sort_by=item_created_at'}>clocks</a> and other <a target={'blank'} href={'https://codepen.io/collection/Akmqje'}>experimental illusions</a> on&nbsp;the&nbsp;web.
          </p>
          </div>
        </main>
      </article>
    </Bootstrap>
  );
};

export default About;
