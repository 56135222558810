import React from 'react';
import './ring-visual.scss';

const Ring = () => {
  return (
    <aside className="ring-wrapper">
      <div className="ring-container">
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
      </div>
    </aside>
  );
};

export default Ring;
